import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';

interface IModalOrderDelivery {
  modalIsOpen: boolean;
  closeModal: () => void;
  onBeebeeModal: () => void;
  onAttachOrdersModal: () => void;
  orderId: number;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const ModalOrderDelivery = ({
  modalIsOpen,
  closeModal,
  orderId,
  onBeebeeModal,
  onAttachOrdersModal,
}: IModalOrderDelivery) => {
  const handleBeebeeModal = () => {
    closeModal();
    onBeebeeModal();
  };

  const handleAttachOrdersModal = () => {
    closeModal();
    onAttachOrdersModal();
  };

  const handleFinishFulfimentOrder = () => {
    closeModal();
  };

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Finalizar separação
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box>
          <Stack
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button
              fullWidth
              size="large"
              onClick={handleBeebeeModal}
              variant="outlined">
              Solicitar frete
            </Button>
            <Button
              fullWidth
              size="large"
              onClick={handleAttachOrdersModal}
              variant="outlined">
              Agrupar frete
            </Button>
            <Button
              fullWidth
              size="large"
              onClick={handleFinishFulfimentOrder}
              variant="outlined">
              Aguardar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalOrderDelivery;
