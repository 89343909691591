import { forwardRef } from 'react';

import { addMinutes, format, isAfter } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';

import { companyUnitSchema, customerSchema, orderSchema } from '@/api/schemes';

import { useStores } from '@/stores/index';

import formatCurrency from '@/utils/formatCurrency';
import formatPhone from '@/utils/formatPhone';
import formatCEP from '@/utils/formatCEP';
import formatWeight from '@/utils/formatWeight';

import {
  Container,
  Paragraph,
  LineInfo,
  LineValue,
  QuantityAndName,
  Quantity,
  ProductName,
  Price,
  Ean,
  Option,
} from './styles';

interface IPrintOrder {
  order?: orderSchema.OrderDetailSchema;
  selectedPaymentMethod?: companyUnitSchema.CompanyUnitPaymentMethod;
}

interface IOrderProductItem {
  product: orderSchema.OrderProduct;
  order?: orderSchema.OrderDetailSchema;
}

interface ReplaceProduct {
  [key: string]: string;
}

type Ref = HTMLDivElement;

const Divider = () => (
  <Paragraph>
    ---------------------------------------------------------------
  </Paragraph>
);

const Address = ({ address }: { address: customerSchema.CustomerAddress }) => (
  <>
    <Paragraph>
      Endereço: {address.street}, {address.number}
    </Paragraph>
    {address.complement && (
      <Paragraph>Complemento: {address.complement} </Paragraph>
    )}
    <Paragraph>Bairro: {address.neighborhood}</Paragraph>
    <Paragraph>Cidade: {address.city}</Paragraph>
    <Paragraph>CEP: {formatCEP(address.zipcode)}</Paragraph>
  </>
);

const OrderProductItem = ({ product, order }: IOrderProductItem) => {
  const currentUnitWeight = product.new_unit_weight || product.unit_weight;

  const canceled = product.status.description === 'Cancelado';

  const quantity =
    product.unit_weight === 'weight'
      ? formatWeight(product.quantity)
      : `${product.quantity} un`;

  const newAmount =
    currentUnitWeight === 'weight'
      ? formatWeight(product.new_amount)
      : `${product.new_amount} un`;

  const unitConversion = product.product.product_base.unit_conversion;
  const currentQuantity = product.new_amount || product.quantity;

  const newPrice = unitConversion
    ? product.price * unitConversion
    : product.price;

  const subtotal =
    currentUnitWeight === 'weight'
      ? product.price * currentQuantity
      : newPrice * currentQuantity;

  const showNewAmount =
    !!product.new_amount && product.new_amount !== product.quantity;

  const productOptions = JSON.parse(product.product_options);

  return (
    <LineInfo component="div">
      <QuantityAndName canceled={canceled}>
        {showNewAmount ? (
          <div>
            <Quantity>{newAmount}</Quantity>
            <Quantity new_amount={showNewAmount}>{quantity}</Quantity>
          </div>
        ) : (
          <Quantity>{quantity}</Quantity>
        )}

        {product.product.product_base.name && (
          <ProductName canceled={canceled}>
            {product.product.product_base.name.toUpperCase()}
            {product.price && (
              <>
                <Price canceled={canceled}>
                  {formatCurrency(product.price)}
                </Price>

                {product.second_price_status === 'active' && (
                  <Price canceled={canceled}>SEGUNDO PREÇO</Price>
                )}
              </>
            )}
            {isAfter(
              product.created_at,
              addMinutes(new Date(order?.created_at as string), 1),
            ) && <span>Produto adicionado</span>}
            <Ean>EAN: {product.product.product_base.barcode}</Ean>

            {productOptions &&
              productOptions.map((option: string) => (
                <Option>• {option}</Option>
              ))}
          </ProductName>
        )}
        {product.price && (
          <Price canceled={canceled}>{formatCurrency(subtotal)}</Price>
        )}
      </QuantityAndName>
    </LineInfo>
  );
};

const PrintOrder = forwardRef<Ref, IPrintOrder>(
  ({ order, selectedPaymentMethod }, ref) => {
    const { companyStore } = useStores();
    const { selectedCompany } = companyStore;

    const replaceProduct: ReplaceProduct = {
      replace: 'Trocar',
      remove: 'Remover',
      contact: 'Entrar em contato',
    };

    const replaceProductOptions = ['replace', 'remove', 'contact'];

    if (!order) {
      return null;
    }

    function groupBy<T extends Record<string, any>, K extends keyof T>(
      array: T[],
      key: K | { (obj: T): string },
    ): Record<string, T[]> {
      const keyFn = key instanceof Function ? key : (obj: T) => obj[key];
      return array.reduce((objectsByKeyValue, obj) => {
        const value = keyFn(obj);
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {} as Record<string, T[]>);
    }

    const productsByCategory = groupBy(
      order.products,
      obj => obj.product.product_base.category.department.name,
    );

    const address = order?.customer.addresses.find(
      address => address.id === order?.deliver_address_id,
    );

    if (!order) {
      return null;
    }

    return (
      <Container ref={ref}>
        <h2>{selectedCompany?.description}</h2>
        <Divider />

        <Paragraph>Nome do cliente: {order.customer.user.nickname}</Paragraph>
        <Paragraph>Telefone: {formatPhone(order.customer.phone)}</Paragraph>
        {address && order.deliver === 'deliver' && (
          <Address address={address} />
        )}
        <Divider />
        <Paragraph>PEDIDO Nº {order.id}</Paragraph>
        <Paragraph>
          Realizado em: {format(new Date(order.created_at), 'dd/MM/yyyy HH:mm')}
        </Paragraph>
        {order.datetime && (
          <Paragraph>
            Dia e horário de entrega:{' '}
            {formatToTimeZone(
              new Date(order.deliver_date as string),
              `DD/MM/YYYY ${order.datetime.starts_at} - ${order.datetime.ends_at} `,
              { timeZone: 'UTC' },
            )}
          </Paragraph>
        )}

        <Paragraph>
          Tipo de entrega:{' '}
          {order.deliver === 'deliver' ? 'Entrega em casa' : 'Retirada na loja'}
        </Paragraph>

        <Paragraph>
          Método de pagamento:{' '}
          {`${selectedPaymentMethod?.name} - ${
            selectedPaymentMethod?.type === 'in-person'
              ? 'Pagamento na Entrega/Retirada'
              : 'Pagamento online'
          }`}
        </Paragraph>

        <Paragraph>
          Caso falte produto:{' '}
          {replaceProductOptions.includes(order.replace_product)
            ? replaceProduct[order.replace_product]
            : order.replace_product}
        </Paragraph>

        {order.additional_information && (
          <Paragraph>Observações: {order.additional_information}</Paragraph>
        )}

        <Divider />

        {Object.entries(productsByCategory).map(([label, products], index) => {
          return (
            <>
              <Paragraph>Categoria: {label}</Paragraph>
              <LineInfo component="div">
                <QuantityAndName>
                  <Quantity>QTD</Quantity>
                  <ProductName>PRODUTO</ProductName>
                </QuantityAndName>
              </LineInfo>
              {products.map(product => (
                <OrderProductItem
                  key={product.id}
                  product={product}
                  order={order}
                />
              ))}
            </>
          );
        })}

        <Divider />

        <LineValue component="span">
          <Paragraph>Valor original do pedido:</Paragraph>
          <Paragraph>{formatCurrency(order.original_total_value)}</Paragraph>
        </LineValue>

        {!!order.totalNotIncludedValue && order.totalNotIncludedValue > 0 && (
          <LineValue component="span">
            <Paragraph>Produtos cancelados:</Paragraph>
            <Paragraph>
              -{formatCurrency(order.totalNotIncludedValue)}
            </Paragraph>
          </LineValue>
        )}

        {!!order.totalIncludedValue && order.totalIncludedValue > 0 && (
          <LineValue component="span">
            <Paragraph>Produtos adicionados:</Paragraph>
            <Paragraph>+{formatCurrency(order.totalIncludedValue)}</Paragraph>
          </LineValue>
        )}

        {!!order.totalModifiedProductsValue && (
          <LineValue component="span">
            <Paragraph>Produtos modificados:</Paragraph>
            <Paragraph>
              {order.totalModifiedProductsValue > 0 && '+'}
              {formatCurrency(order.totalModifiedProductsValue)}
            </Paragraph>
          </LineValue>
        )}

        <LineValue component="span">
          <Paragraph>Total de produtos:</Paragraph>
          <Paragraph>{formatCurrency(order.totalValue)}</Paragraph>
        </LineValue>

        <LineValue component="span">
          <Paragraph>Taxa de entrega:</Paragraph>
          <Paragraph>{formatCurrency(order.deliver_value)}</Paragraph>
        </LineValue>

        <LineValue component="span">
          <Paragraph>Taxa de serviço:</Paragraph>
          <Paragraph>{formatCurrency(order.service_tax_value)}</Paragraph>
        </LineValue>

        <LineValue component="span">
          <Paragraph>Gorjeta:</Paragraph>
          <Paragraph>{formatCurrency(order.tip)}</Paragraph>
        </LineValue>

        <LineValue component="span">
          <Paragraph>Total da compra:</Paragraph>
          <Paragraph> {formatCurrency(order.finalValue)}</Paragraph>
        </LineValue>

        <Divider />
      </Container>
    );
  },
);

export default PrintOrder;
