import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  SyntheticEvent,
} from 'react';
import {
  Avatar,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
  Switch as MUISwitch,
} from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Iconify from '@/components/iconyfy';
import { green } from '@mui/material/colors';

import ModalBase from '../modalBase';
import Input from '@/components/Input';
import { companyUnitSchema } from '@/api/schemes';
import Switch from '@/components/Switch';
import Scrollbar from '@/components/scrollbar';
import InputCurrency from '@/components/InputCurrency';
import InputMask from '@/components/InputMask';
import TableHead from '@/components/TableHead';

import {
  useAddCompanyUnit,
  useUpdateCompanyUnit,
} from '@/hooks/queries/companyUnit';

import { ParentScroll, FormRow, SectionTitle, StoreLogo } from './styles';
import InputPercentage from '@/components/InputPercentage';
import { useGetProductList } from '@/hooks/queries/product';
import formatCurrency from '@/utils/formatCurrency';

interface ModalAddEditStoreForm {
  id?: number;
  name: string;
  description: string;
  zipcode: string;
  street: string;
  number: number;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  image: File;
  exclusive_in_app_pricing: string;
  freight_status: string;
  withdraw_status: string;
  minimal_order_value: number;
  maximum_employee_order_approval: number;
  suggestion_mail: string;
  service_tax: string;
  service_tax_value: number;
  enable_beebee: string;
  variable_service_tax: string;
  variable_service_tax_value: number;
  variable_service_tax_minimum_value: number;
  variable_service_tax_maximum_value: number;
  whatsapp: string;
  enable_melivery_freight: string;
  customer_pays_freight: string;
}

interface IModalAddEditStore {
  modalIsOpen: boolean;
  closeModal: () => void;
  store?: companyUnitSchema.CompanyUnitSchema;
}

const addEditUserSchema = yup.object().shape({
  id: yup.number(),
  name: yup.string().required('Nome Obrigatório').nullable(),
  description: yup.string().required('Descrição Obrigatória').nullable(),
  zipcode: yup.string().required('Cep obrigatório').nullable(),
  street: yup.string().required('Endereço obrigatório').nullable(),
  number: yup.number().required('Número obrigatório').nullable(),
  neighborhood: yup.string().required('Bairro obrigatório').nullable(),
  city: yup.string().required('Cidade obrigatória').nullable(),
  state: yup.string().required('Estado obrigatório').nullable(),
  suggestion_mail: yup
    .string()
    .email('E-mail inválido')
    .required('Estado obrigatório')
    .nullable(),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const TABLE_HEAD = [
  { id: 'variations.0.images.0.image_url', label: '', alignRight: false },
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'department', label: 'Departamento', alignRight: false },
  { id: 'company_units_details.0.price', label: 'Preço', alignRight: false },
  { id: 'active', label: '', alignRight: false },
];
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ mt: 5 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `modal-queue-list-tab-${index}`,
    'aria-controls': `modal-queue--list-tabpanel-${index}`,
  };
}

const ModalAddEditStore = ({
  modalIsOpen,
  closeModal,
  store,
}: IModalAddEditStore) => {
  const { data: productList, isFetching } = useGetProductList({
    limit: '20',
  });

  const { control, handleSubmit, reset } = useForm<ModalAddEditStoreForm>({
    resolver: yupResolver(addEditUserSchema),
  });

  const [companyUnitActive, setCompanyUnitActive] = useState(false);
  const [exclusiveInApp, setExclusiveInApp] = useState(false);
  const [freightStatus, setFreightStatus] = useState(false);
  const [withdrawStatus, setWithdrawStatus] = useState(false);
  const [serviceTax, setServiceTax] = useState(false);
  const [beebee, setBeebee] = useState(false);
  const [variableServiceTax, setVariableServiceTax] = useState(false);
  const [enableMeliveryFreight, setEnableMeliveryFreight] = useState(false);
  const [customerPaysFreight, setCustomerPaysFreight] = useState(false);

  const [tab, setTab] = useState(0);

  const handleCompanyUnitStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setCompanyUnitActive(event.target.checked);
  };

  const handleExclusiveInApp = (event: ChangeEvent<HTMLInputElement>) => {
    setExclusiveInApp(event.target.checked);
  };

  const handleFreightStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setFreightStatus(event.target.checked);
  };

  const handleWithdrawStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setWithdrawStatus(event.target.checked);
  };

  const handleServiceTax = (event: ChangeEvent<HTMLInputElement>) => {
    setServiceTax(event.target.checked);
  };

  const handleBeebee = (event: ChangeEvent<HTMLInputElement>) => {
    setBeebee(event.target.checked);
  };

  const handleVariableServiceTax = (event: ChangeEvent<HTMLInputElement>) => {
    setVariableServiceTax(event.target.checked);
  };

  const handleEnableMeliveryFreight = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setEnableMeliveryFreight(event.target.checked);
  };

  const handleCustomerPaysFreight = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomerPaysFreight(event.target.checked);
  };

  const { mutate: addCompanyUnit } = useAddCompanyUnit();
  const { mutate: updateCompanyUnit } = useUpdateCompanyUnit();

  const onSubmit: SubmitHandler<ModalAddEditStoreForm> = async data => {
    const {
      city,
      complement,
      description,
      minimal_order_value,
      maximum_employee_order_approval,
      name,
      neighborhood,
      number,
      state,
      street,
      zipcode,
      image,
      suggestion_mail,
      service_tax_value,
      variable_service_tax_value,
      variable_service_tax_minimum_value,
      variable_service_tax_maximum_value,
      whatsapp,
    } = data;

    const formData = new FormData();
    formData.set('number', number.toString());

    if (!isNaN(minimal_order_value) && minimal_order_value !== null) {
      formData.set('minimal_order_value', minimal_order_value?.toString());
    }

    if (!isNaN(service_tax_value) && service_tax_value !== null) {
      formData.set('service_tax_value', service_tax_value?.toString());
    }
    if (maximum_employee_order_approval) {
      formData.set(
        'maximum_employee_order_approval',
        maximum_employee_order_approval?.toString(),
      );
    }
    if (variable_service_tax_value) {
      formData.set(
        'variable_service_tax_value',
        variable_service_tax_value?.toString(),
      );
    }
    if (variable_service_tax_minimum_value) {
      formData.set(
        'variable_service_tax_minimum_value',
        variable_service_tax_minimum_value?.toString(),
      );
    }
    if (variable_service_tax_maximum_value) {
      formData.set(
        'variable_service_tax_maximum_value',
        variable_service_tax_maximum_value?.toString(),
      );
    }

    formData.set('city', city);
    formData.set('complement', complement || '');
    formData.set('description', description);
    formData.set('name', name);
    formData.set('neighborhood', neighborhood);
    formData.set('state', state);
    formData.set('street', street);
    formData.set('zipcode', zipcode);
    formData.set('whatsapp', whatsapp?.replace(/[^0-9]/g, ''));
    formData.set('suggestion_mail', suggestion_mail);
    formData.set(
      'exclusive_in_app_pricing',
      exclusiveInApp ? 'active' : 'inactive',
    );
    formData.set('freight_status', freightStatus ? 'active' : 'inactive');
    formData.set('withdraw_status', withdrawStatus ? 'active' : 'inactive');
    formData.set('service_tax', serviceTax ? 'yes' : 'no');
    formData.set('enable_beebee', beebee ? 'active' : 'inactive');
    formData.set('variable_service_tax', variableServiceTax ? 'yes' : 'no');
    formData.set(
      'enable_melivery_freight',
      enableMeliveryFreight ? 'yes' : 'no',
    );
    formData.set('customer_pays_freight', customerPaysFreight ? 'yes' : 'no');

    if (image) {
      formData.append('image', image);
    }

    if (store) {
      formData.set('status', companyUnitActive ? 'active' : 'inactive');
      updateCompanyUnit({
        id: store.id,
        data: formData,
      });
    } else {
      addCompanyUnit(formData);
    }

    closeModal();
  };

  const uploadInputRef = useRef<any>(null);
  const logoImageRef = useRef<any>(null);

  const handleChangeLogo = (file: File) => {
    const reader = new FileReader();

    reader.onload = () => {
      logoImageRef.current.src = reader.result;
    };

    reader.readAsDataURL(file);
  };

  const handleChangeTab = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (store) {
      const {
        city,
        complement,
        description,
        exclusive_in_app_pricing,
        minimal_order_value,
        maximum_employee_order_approval,
        name,
        neighborhood,
        number,
        state,
        street,
        zipcode,
        status,
        suggestion_mail,
        freight_status,
        withdraw_status,
        service_tax,
        service_tax_value,
        enable_beebee,
        variable_service_tax,
        variable_service_tax_value,
        variable_service_tax_minimum_value,
        variable_service_tax_maximum_value,
        whatsapp,
        enable_melivery_freight,
        customer_pays_freight,
      } = store;
      reset({
        city,
        complement,
        description,
        exclusive_in_app_pricing,
        minimal_order_value,
        maximum_employee_order_approval,
        name,
        neighborhood,
        number,
        state,
        street,
        zipcode,
        suggestion_mail,
        service_tax_value,
        variable_service_tax_value,
        variable_service_tax_minimum_value,
        variable_service_tax_maximum_value,
        whatsapp,
      });

      setServiceTax(service_tax === 'yes' ? true : false);
      setCompanyUnitActive(status === 'active' ? true : false);
      setExclusiveInApp(exclusive_in_app_pricing === 'active' ? true : false);
      setFreightStatus(freight_status === 'active' ? true : false);
      setWithdrawStatus(withdraw_status === 'active' ? true : false);
      setBeebee(enable_beebee === 'active' ? true : false);
      setVariableServiceTax(variable_service_tax === 'yes' ? true : false);
      setEnableMeliveryFreight(
        enable_melivery_freight === 'yes' ? true : false,
      );
      setCustomerPaysFreight(customer_pays_freight === 'yes' ? true : false);
    }
  }, [store, reset]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        {/* <Overlay loading={true}> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Liberação de loja
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            aria-label="queue tabs"
            variant="fullWidth"
            scrollButtons={true}
            allowScrollButtonsMobile>
            <Tab
              label="Dados Cadastrais"
              iconPosition="end"
              {...a11yProps(0)}
            />
            <Tab label="Produtos" iconPosition="end" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <ParentScroll>
          <Scrollbar>
            <TabPanel value={tab} index={0}>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off">
                <SectionTitle>Dados Cadastrais</SectionTitle>
                <Stack spacing={3}>
                  <FormRow>
                    <Input
                      control={control}
                      label="Nome"
                      name="name"
                      previousData="Melivery"
                    />
                    <Input
                      control={control}
                      label="Descrição"
                      name="description"
                      previousData="O Marketplace do frete grátis!"
                    />
                  </FormRow>

                  <FormRow>
                    <InputMask
                      mask="99999-999"
                      control={control}
                      label="CEP"
                      name="zipcode"
                    />
                    <Input control={control} label="Endereço" name="street" />
                  </FormRow>

                  <FormRow>
                    <Input
                      control={control}
                      label="Número"
                      name="number"
                      type="number"
                    />
                    <Input
                      control={control}
                      label="Complemento"
                      name="complement"
                    />
                  </FormRow>

                  <FormRow>
                    <Input
                      control={control}
                      label="Bairro"
                      name="neighborhood"
                    />
                    <Input control={control} label="Cidade" name="city" />
                  </FormRow>

                  <FormRow>
                    <Input control={control} label="Estado" name="state" />

                    <InputMask
                      mask="(99) 99999-9999"
                      control={control}
                      label="WhatsApp"
                      name="whatsapp"
                    />
                  </FormRow>

                  <SectionTitle>Logo</SectionTitle>

                  <StoreLogo
                    ref={logoImageRef}
                    src={store?.image_url}
                    alt="Logo"
                  />

                  <FormRow>
                    <Controller
                      name="image"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <>
                          <input
                            ref={uploadInputRef}
                            accept="image/*"
                            type="file"
                            hidden
                            onChange={(e: any) => {
                              const file = e.target.files[0];
                              onChange(file);
                              handleChangeLogo(file);
                            }}
                          />

                          <Button
                            onClick={() => uploadInputRef.current.click()}
                            variant="text"
                            size="large"
                            // fullWidth
                            startIcon={
                              <Iconify icon="mdi:image-edit-outline" />
                            }>
                            Alterar logo
                          </Button>
                        </>
                      )}
                    />
                  </FormRow>

                  <SectionTitle>Configurações da Loja</SectionTitle>

                  <FormRow>
                    <InputCurrency
                      label="Valor mínimo de compra"
                      control={control}
                      name="minimal_order_value"
                    />
                    <InputCurrency
                      label="Valor máximo de aprovação de pedido (operador)"
                      control={control}
                      name="maximum_employee_order_approval"
                    />
                  </FormRow>

                  <FormRow>
                    <Input
                      control={control}
                      label="E-mail para sugestão de produtos"
                      name="suggestion_mail"
                    />
                  </FormRow>

                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="service_tax"
                        checked={serviceTax}
                        onChange={handleServiceTax}
                      />
                    }
                    label="Habilitar taxa de serviço"
                  />

                  {serviceTax && (
                    <>
                      <FormRow>
                        <InputCurrency
                          label="Taxa de Serviço"
                          control={control}
                          name="service_tax_value"
                        />
                      </FormRow>
                      <FormControlLabel
                        control={
                          <Switch
                            control={control}
                            name="variable_service_tax"
                            checked={variableServiceTax}
                            onChange={handleVariableServiceTax}
                          />
                        }
                        label="Habilitar taxa de serviço variável (Sobre o valor economizado)"
                      />
                      {variableServiceTax && (
                        <>
                          <FormRow>
                            <InputPercentage
                              label="Taxa de serviço variável (Valor em %)"
                              control={control}
                              name="variable_service_tax_value"
                            />
                          </FormRow>
                          <FormRow>
                            <InputCurrency
                              label="Valor mínimo para cobrança de taxa de serviço variável"
                              control={control}
                              name="variable_service_tax_minimum_value"
                            />
                            <InputCurrency
                              label="Valor máximo para cobrança de taxa de serviço variável"
                              control={control}
                              name="variable_service_tax_maximum_value"
                            />
                          </FormRow>
                        </>
                      )}
                    </>
                  )}

                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="exclusive_in_app_pricing"
                        checked={exclusiveInApp}
                        onChange={handleExclusiveInApp}
                      />
                    }
                    label="Habilitar seção de produtos exclusivos no app"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="freight_status"
                        checked={freightStatus}
                        onChange={handleFreightStatus}
                      />
                    }
                    label="Habilitar entrega"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="withdraw_status"
                        checked={withdrawStatus}
                        onChange={handleWithdrawStatus}
                      />
                    }
                    label="Habilitar retirada"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="enable_beebee"
                        checked={beebee}
                        onChange={handleBeebee}
                      />
                    }
                    label="Habilitar integração com a Beebee"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        control={control}
                        name="enable_melivery_freight"
                        checked={enableMeliveryFreight}
                        onChange={handleEnableMeliveryFreight}
                      />
                    }
                    label="Habilitar frete melivery"
                  />

                  {enableMeliveryFreight && (
                    <FormControlLabel
                      control={
                        <Switch
                          control={control}
                          name="customer_pays_freight"
                          checked={customerPaysFreight}
                          onChange={handleCustomerPaysFreight}
                        />
                      }
                      label="Frete melivery por conta do cliente"
                    />
                  )}

                  {store && (
                    <FormControlLabel
                      control={
                        <Switch
                          control={control}
                          name="status"
                          checked={companyUnitActive}
                          onChange={handleCompanyUnitStatus}
                        />
                      }
                      label="Loja ativa"
                    />
                  )}
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{
                    xs: 0.5,
                    sm: 1,
                  }}
                  sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="text"
                    onClick={closeModal}>
                    Cancelar
                  </Button>
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    Liberar Loja
                  </Button>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <TableContainer>
                <Table>
                  <TableHead headLabels={TABLE_HEAD} />
                  <TableBody>
                    {productList?.data.map(row => {
                      const {
                        id,
                        name,
                        category,
                        variations,
                        company_units_details,
                      } = row;

                      return (
                        <TableRow
                          hover
                          // selected={id === selectedProduct?.id}
                          key={id}
                          tabIndex={-1}
                          // onClick={() => handleModalProduct(row)}
                          sx={{
                            '&.Mui-selected': {
                              bgcolor: 'action.selected',
                            },
                            cursor: 'pointer',
                          }}>
                          <TableCell align="left">
                            <Avatar
                              sx={{
                                bgcolor: green[500],
                                width: 36,
                                height: 36,
                              }}
                              src={variations[0].images[0]?.image_url}
                              alt={name}
                              sizes=""
                            />
                          </TableCell>

                          <TableCell align="left">{id}</TableCell>

                          <TableCell align="left">{name}</TableCell>

                          <TableCell align="left">
                            {category.department.name}
                          </TableCell>

                          <TableCell align="left">
                            {formatCurrency(company_units_details[0].price)}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={e => e.stopPropagation()}>
                            <MUISwitch
                              name="active"
                              checked={false}
                              onChange={() => {}}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </Scrollbar>
        </ParentScroll>
        {/* </Overlay> */}
      </Box>
    </ModalBase>
  );
};

export default ModalAddEditStore;
