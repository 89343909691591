import { SyntheticEvent, useState } from 'react';
import {
  Badge,
  BadgeProps,
  Box,
  Card,
  Container,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';

import { useGetCompanyUnitList } from '@/hooks/queries/companyUnit';

import { companyUnitSchema } from '@/api/schemes';

import ModalAddEditStoreQueue from '@/components/modals/modalAddEditStoreQueue';
import Pending from './pending';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ mt: 5 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `queue-list-tab-${index}`,
    'aria-controls': `queue--list-tabpanel-${index}`,
  };
}

function Queue() {
  const { data: companyUnits } = useGetCompanyUnitList();
  const [tab, setTab] = useState(0);

  const [openStoreModal, setOpenStoreModal] = useState(false);
  const [selectedStore, setSelectedStore] =
    useState<companyUnitSchema.CompanyUnitSchema>();

  const pendingLength = companyUnits?.data
    ?.filter(company => company.published === 'no')
    .map(el => el).length;

  const handleStoreModal = (store?: companyUnitSchema.CompanyUnitSchema) => {
    if (store) {
      setSelectedStore(store);
    } else {
      setSelectedStore(undefined);
    }
    setOpenStoreModal(!openStoreModal);
  };

  const handleChangeTab = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
      right: -7,
      padding: '7px 4px',
    },
  }));

  return (
    <>
      <Helmet>
        <title>Esteira</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}>
          <Typography variant="h4" gutterBottom>
            Esteira de Lojas
          </Typography>
        </Stack>
        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              aria-label="queue tabs"
              variant="fullWidth"
              scrollButtons={true}
              allowScrollButtonsMobile>
              <Tab
                label="Em cadastro"
                icon={
                  <StyledBadge badgeContent={pendingLength} color="primary" />
                }
                iconPosition="end"
                style={{ minHeight: 45 }}
                {...a11yProps(0)}
              />
              <Tab
                label="Pendentes"
                icon={<StyledBadge badgeContent={0} color="primary" />}
                iconPosition="end"
                style={{ minHeight: 45 }}
                {...a11yProps(1)}
              />
              <Tab
                label="Finalizadas"
                icon={<StyledBadge badgeContent={0} color="primary" />}
                iconPosition="end"
                style={{ minHeight: 45 }}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            <Pending
              storeList={companyUnits?.data}
              selectedStore={selectedStore}
              handleStoreModal={handleStoreModal}
            />
          </TabPanel>
        </Card>
      </Container>

      <ModalAddEditStoreQueue
        modalIsOpen={openStoreModal}
        closeModal={handleStoreModal}
        store={selectedStore}
      />
    </>
  );
}

export default Queue;
