import { useEffect, useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { orderQueries } from '@/hooks/queries';

import Iconify from '@/components/iconyfy';

import ModalBase from '../modalBase';

import { addDays, setHours } from 'date-fns';
import Autocomplete from '@/components/Autocomplete';
import { useStores } from '@/stores/index';

interface IModalAttachOrders {
  modalIsOpen: boolean;
  closeModal: () => void;
  orderId: number;
  cpfCustomer?: string;
}

interface ModalAddProductForm {
  attach_orders_id: { label: string; value: number }[];
}

interface OrderOption {
  label: string;
  value: number;
}

const addOrderProductSchema = yup.object().shape({
  attach_orders_id: yup.number().required('É necessário selecionar um pedido'),
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  borderRadius: 2,
};

const filterDate = {
  starts_at: setHours(new Date(), 0),
  ends_at: setHours(new Date(), 23),
};

const ModalAttachOrders = ({
  modalIsOpen,
  closeModal,
  orderId,
  cpfCustomer,
}: IModalAttachOrders) => {
  const { companyStore } = useStores();
  const { selectedCompany } = companyStore;

  const [orders, setOrders] = useState<OrderOption[]>([]);

  const { control, handleSubmit, reset } = useForm<ModalAddProductForm>({
    // resolver: yupResolver(addOrderProductSchema),
  });

  const { data: orderData, refetch } = orderQueries.useGetOrderList({
    company_unit_id: selectedCompany?.id,
    starts_at: filterDate.starts_at,
    ends_at: addDays(filterDate.ends_at, 1),
    cpf: cpfCustomer?.replace(/[^0-9]/g, ''),
    status_id: 13,
  });

  const { mutateAsync: attachOrders } = orderQueries.useAttachOrders();

  const onSubmit: SubmitHandler<ModalAddProductForm> = async data => {
    const { attach_orders_id } = data;

    const newAttachOrdersIds = attach_orders_id?.map(option =>
      Number(option.value),
    );

    await attachOrders({
      order_id: orderId,
      attach_orders_id: newAttachOrdersIds,
    });

    closeModal();
  };

  useEffect(() => {
    const newOrders = orderData?.data
      .filter(order => order.id !== orderId)
      .map(order => ({
        label: `${order.id.toString()}`,
        value: order.id,
      }));

    if (newOrders) {
      setOrders(newOrders);
    }
  }, [orderData, orderId]);

  useEffect(() => {
    reset({});
    refetch();
  }, [modalIsOpen, reset, refetch]);

  return (
    <ModalBase modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Agrupar frete
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              color: 'text.primary',
            }}>
            <Iconify icon="mdi:window-close" />
          </IconButton>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Stack spacing={3}>
            <Autocomplete
              label="Selecionar pedido(s)"
              name="attach_orders_id"
              control={control}
              options={orders}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ borderTop: 1, borderColor: 'divider', paddingTop: 2 }}>
            <Button fullWidth size="large" type="submit" variant="contained">
              Agrupar
            </Button>
          </Stack>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalAttachOrders;
