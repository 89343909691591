import { Button, Typography } from '@mui/material';

import { LineInfo } from './styles';
import Iconify from '../iconyfy';

interface IOrderDeliveryDetailItem {
  link: string;
  statusId: number;
}

const OrderDeliveryDetailItem = ({
  link,
  statusId,
}: IOrderDeliveryDetailItem) => {
  const handleOpenLink = () => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <LineInfo>
      <Typography variant="subtitle1" color="#444" fontSize={14}>
        <Button
          onClick={handleOpenLink}
          variant="contained"
          disabled={statusId !== 4 && statusId !== 6}
          startIcon={<Iconify icon="mdi:delivery-dining-outline" />}>
          Acompanhar frete
        </Button>
      </Typography>
    </LineInfo>
  );
};

export default OrderDeliveryDetailItem;
