import { TextFieldProps } from '@mui/material';
import { Controller } from 'react-hook-form';

import { TextField } from './styles';

export type InputProps = {
  name: string;
  control: any;
  previousData?: string;
} & TextFieldProps;

const Input = ({
  name,
  control,
  previousData,
  ...textFieldProps
}: InputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          error={!!error?.message}
          helperText={
            !!error?.message
              ? !!error?.message
              : previousData
              ? `Dado anterior: ${previousData}`
              : ''
          }
          {...textFieldProps}
          {...field}
        />
      )}
    />
  );
};

export default Input;
