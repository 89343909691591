import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import TableHead from '@/components/TableHead';
import { companyUnitSchema } from '@/api/schemes';

interface PendingProps {
  storeList?: companyUnitSchema.CompanyUnitSchema[];
  selectedStore?: companyUnitSchema.CompanyUnitSchema;
  handleStoreModal: (store?: companyUnitSchema.CompanyUnitSchema) => void;
}

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'description', label: 'Descrição', alignRight: false },
  { id: 'city', label: 'Cidade', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

function Pending({ storeList, selectedStore, handleStoreModal }: PendingProps) {
  return (
    <>
      <TableContainer>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            padding: 2,
          }}></Stack>
        <Table>
          <TableHead headLabels={TABLE_HEAD} />
          <TableBody>
            {storeList?.map(row => {
              const { id, name, city, description } = row;

              return (
                <TableRow
                  hover
                  selected={id === selectedStore?.id}
                  key={id}
                  tabIndex={-1}
                  onClick={() => handleStoreModal(row)}
                  sx={{
                    '&.Mui-selected': {
                      bgcolor: 'action.selected',
                    },
                    cursor: 'pointer',
                  }}>
                  <TableCell align="left">{id}</TableCell>

                  <TableCell align="left">{name}</TableCell>

                  <TableCell align="left">{description}</TableCell>

                  <TableCell align="left">{city}</TableCell>
                  <TableCell align="left">Em cadastro</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Pending;
