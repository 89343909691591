import { orderRequests } from '@/api/requests';
import { orderSchema, statusSchema } from '@/api/schemes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

export function useGetOrderList(params?: orderSchema.GetOrderParams) {
  const queryClient = useQueryClient();
  async function requestFn() {
    return orderRequests.getOrders(params!);
  }

  const query = useQuery<orderSchema.OrderList>(
    ['getOrders', params],
    requestFn,
    {
      enabled: Boolean(params?.company_unit_id) && !!params?.ends_at,
      onSuccess: () => {
        queryClient.invalidateQueries('getOrder');
      },
    },
  );

  return query;
}

export function useGetOrderStatusList() {
  async function requestFn() {
    return orderRequests.getOrderStatuses();
  }

  const query = useQuery<statusSchema.StatusList>(
    ['getOrdersStatusList'],
    requestFn,
  );

  return query;
}

export function useGetUniqueOrder(params?: orderSchema.GetUniqueOrderParam) {
  async function requestFn() {
    return orderRequests.getOrder(params!);
  }

  const query = useQuery<orderSchema.OrderDetail>(
    ['getOrder', params],
    requestFn,
    {
      enabled: Boolean(params),
      staleTime: 4000,
    },
  );

  return query;
}

export function useGetOrderDossier(
  params?: orderSchema.GetUniqueOrderDossierParams,
) {
  async function requestFn() {
    return orderRequests.getOrderDossier(params!);
  }

  const query = useQuery<orderSchema.OrderDetail>(
    ['getOrderDossier', params],
    requestFn,
    {
      enabled: params?.status_id === 7 || params?.status_id === 8,
      staleTime: 4000,
    },
  );

  return query;
}

export function useUpdateOrder() {
  const queryClient = useQueryClient();

  async function mutationFn(params: orderSchema.UpdateOrderParams) {
    return orderRequests.updateOrder(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getOrders');
      queryClient.invalidateQueries('getOrder');
      toast.success('Pedido atualizado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização do pedido', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useAddOrderProduct() {
  const queryClient = useQueryClient();

  async function mutationFn(params: orderSchema.AddOrderProductParams) {
    return orderRequests.addOrderProduct(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getOrder');
      toast.success('Produto adicionado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na adição do Produto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useRemoveOrderProduct() {
  const queryClient = useQueryClient();

  async function mutationFn(params: orderSchema.RemoveOrderProductParams) {
    return orderRequests.removeOrderProduct(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getOrder');
      toast.success('Produto removido com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na remoção do Produto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateOrderProduct() {
  const queryClient = useQueryClient();
  async function mutationFn(params: orderSchema.UpdateOrderProductParams) {
    return orderRequests.updateOrderProduct(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getOrder');
      toast.success('Produto atualizado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização do Produto', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useCaptureOrderPayment() {
  async function mutationFn(params: orderSchema.CaptureOrderPaymentParams) {
    return orderRequests.captureOrderPayment(params!);
  }

  const mutation = useMutation(mutationFn);

  return mutation;
}

export function useCancelOrderPayment() {
  async function mutationFn(params: orderSchema.CancelOrderPaymentParams) {
    return orderRequests.cancelOrderPayment(params!);
  }

  const mutation = useMutation(mutationFn);

  return mutation;
}

export function useCancelOrderPaymentPartial() {
  async function mutationFn(params: orderSchema.CancelOrderPaymentParams) {
    return orderRequests.cancelOrderPaymentPartial(params!);
  }

  const mutation = useMutation(mutationFn);

  return mutation;
}

export function useAttachOrders() {
  const queryClient = useQueryClient();
  async function mutationFn(params: orderSchema.AttachOrdersParams) {
    return orderRequests.attachOrders(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getOrder');
      toast.success('Frete agrupado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro ao agrupar o frete.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
